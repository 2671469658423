/* eslint-disable react/no-multi-comp */
import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'


import { Can } from 'lib/can'
import { List, Loader } from 'components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { FETCH_PROPERTY_LIST, DELETE_PROPERTY } from 'constants/queries';
import { useTranslation } from 'react-i18next'
import { StoreContext } from 'App'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  dangerButton: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  }
}));

const CreateProperty = () => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Can I='create' this='property'>
      <Button onClick={() => history.push('/properties/create')}>
        {`${t('create')} ${t('property')}`}
      </Button>
    </Can>
  )
}


const Actions = ({ prop }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const context = useContext(StoreContext)
  const classes = useStyles()
  const [deleteProperty, deletionStatus] = useMutation(DELETE_PROPERTY, {
    onCompleted: () => {
      context.state.hideDialog()
      context.state.showAlert({
        severity: 'warning',
        message: t('x_deleted', { x: t('property') })
      })
      window.location.reload();
    }
  })

  const confirmDeletion = () => {
    deleteProperty({ variables: { id: prop.id } })
  }

  if (deletionStatus.loading) debugger

  return (
    <React.Fragment>
      <Button
        color='primary'
        variant='contained'
        style={{ margin: '0.25em' }}
        onClick={() => history.push('/properties/view/' + prop.id)}
      >
        {t('action_see')}
      </Button>

      <Can I='destroy' this='property'>
        <Button
          color='primary'
          variant='contained'
          style={{ margin: '0.25em' }}
          onClick={() => {
            context.state.showDialog({
              title: t('confirm_deletion'),
              contentText: t('are_you_sure_delete?'),
              actions: () => {
                return (
                  <React.Fragment>
                    <Button className={classes.dangerButton} disabled={deletionStatus.loading} color='primary' variant='contained' onClick={confirmDeletion}>{t('im_sure')} </Button>
                    <Button color='secondary' variant='contained' onClick={context.state.hideDialog}>{t('cancel')} </Button>
                  </React.Fragment>
                )
              }
            })
          }}
        >
          {t('delete')}
        </Button>
      </Can>
    </React.Fragment>
  )
}

const PropertyList = () => {
  const classes = useStyles();
  const { data } = useQuery(FETCH_PROPERTY_LIST, { fetchPolicy: 'network-only' });
  const { t } = useTranslation();

  const headCells = [
    {
      id: 'id',
      numeric: true,
      label: t('policyId'),
      searchable: true,
      disablePadding: true,
      fn: p => parseInt(p.lastPolicy.id),
      display: p => '#' + p.lastPolicy.id
    },
    {
      id: 'ownerName',
      numeric: false,
      label: t('client'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => p.user.fullName,
      display: p => p.user.fullName
    },
    {
      id: 'policyType',
      numeric: false,
      label: t('policyType'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => t(p.lastPolicy.policyType),
      display: p => t(p.lastPolicy.policyType),
    },
    {
      id: 'rent',
      numeric: false,
      label: t('monthlyRent'),
      searchable: true,
      disablePadding: true,
      fn: p => p.lastContract.monthlyRent.toString(),
      display: p => p.lastContract.monthlyRent.toString()
    },
    {
      id: 'city',
      numeric: false,
      label: t('city'),
      numeric: false,
      searchable: true,
      disablePadding: true,
      fn: p => t(p.address.state),
      display: p => t(p.address.state)
    },
    {
      id: 'zipcode',
      numeric: false,
      label: t('zipcode'),
      numeric: false,
      searchable: true,
      disablePadding: false,
      fn: p => p.address.zipCode,
      display: p => p.address.zipCode
    },
    {
      id: 'propertyUse',
      numeric: false,
      label: t('propertyUse'),
      numeric: false,
      searchable: true,
      disablePadding: false,
      fn: p => t(p.propertyUse),
      display: p => t(p.propertyUse)
    },
    {
      id: 'actions',
      numeric: false,
      label: t('actions'),
      disablePadding: true,
      searchable: false,
      display: prop => <Actions prop={prop} />
    }
  ];


  if (data) {
    return (
      <List
        label={t('properties')}
        subject={data.properties}
        headCells={headCells}
        HeaderActions={() => <CreateProperty />}
      />
    );
  }
  return <Loader />;
};

export default PropertyList;

