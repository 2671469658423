import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { parseAddress, parseCurrency } from 'lib/helpers'
import { Loader, List } from 'components'
import { useQuery } from '@apollo/react-hooks';
import { FETCH_INVOICES_LIST } from 'constants/queries';
import { Chip } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  validChip: {
    backgroundColor: theme.palette.success.light,
    color: 'white',
    margin: '0 0 0 1em'
  },
  lateChip: {
    backgroundColor: theme.palette.error.light,
    color: 'white',
    margin: '0 0 0 1em'
  }
}));

const Actions = ({inv}) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Button
      color='primary'
      variant='contained'
      onClick={() => history.push('/invoices/view/' + inv.id)}
    >
      {t('action_see')}
    </Button>
  )
}

const InvoiceList = () => {
  const { data } = useQuery(FETCH_INVOICES_LIST, { fetchPolicy: 'network-only' });
  const { t } = useTranslation()
  const classes = useStyles()


  const AssignedDateCell = ({inv}) => {
    const text = moment(inv.assignedDate).format('MMM DD, YYYY')
    return (
    <div>
        {text}
        {inv.isLate && (
          <Chip label={t('late')} className={classes.lateChip}/>
        )}
        {inv.isPayed && (
          <Chip label={t('payed')} className={classes.validChip}/>
        )}
    </div>
    )
  }


  if(data) {
    const headCells = [
      {
        id: 'id',
        numeric: true,
        label: 'ID',
        searchable: true,
        disablePadding: true,
        fn: p => Number(p.id),
        display: p => '#' + p.id
      }, {
        id: 'policyId',
        numeric: false,
        label: t('policyId'),
        disablePadding: true,
        searchable: true,
        fn: i => i.policy.id,
        display: i => i.policy.id
      }, {
        id: 'user',
        numeric: false,
        label: t('owner'),
        disablePadding: true,
        searchable: true,
        fn: i => i.user.fullName,
        display: i => i.user.fullName,

      },{
        id: 'address',
        numeric: false,
        label: t('address'),
        disablePadding: true,
        searchable: true,
        fn: i => parseAddress(i.address, t),
        display: i => parseAddress(i.address, t),
      }, {
        id: 'policyType',
        numeric: false,
        label: t('policyType'),
        disablePadding: true,
        searchable: true,
        fn: i => t(i.policy.policyType),
        display: i => t(i.policy.policyType),
      }, {
        id: 'total',
        numeric: false,
        label: t('total'),
        disablePadding: true,
        searchable: true,
        fn: i => parseCurrency(i.total),
        display: i => '$' + parseCurrency( i.total),
      },{
        id: 'assignedDate',
        numeric: false,
        label: t('assignedDate'),
        disablePadding: true,
        searchable: true,
        fn: i => moment(i.assignedDate).format('MMM DD, YYYY') + ' ' + (i.isLate && t('late')) + ' ' + (i.isPayed && t('payed')),
        display: inv => <AssignedDateCell inv={inv}/>,
        sortFn: i => new Date(i.assignedDate)
      }, {
        id: 'actions',
        numeric: false,
        label: t('actions'),
        disablePadding: true,
        searchable: false,
        display: inv => <Actions inv={inv}/>
      }
    ]
      return (
        <List
          label={t('invoices')}
          subject={data.invoices}
          headCells={headCells}
        />
      )
  }
  else
    return <Loader/>
}

export default InvoiceList;
